import { GImage } from '@/Components/g-image';
import { initializeApollo } from '@/Lib/graphql/apollo-client';
import { GetStaticProps } from 'next';
import { navigationDataFn } from '@/Lib/function/navigation';
import { getMeta } from '@/Components/meta/meta.query';
import { getHeaderData } from '@/Lib/graphql/helpers/header-data';
import { LinkButton } from '@/Components/button/link';
import { getOfficeData } from '@/Lib/graphql/helpers/office-data';

export default function GWAT404() {
  return (
    <main className="my-16">
      <section className="max-w-6.75xl mx-auto">
        <div className="mx-5 text-center">
          <h2 className="text-4xl leading-9 mb-2.5 text-dark-900 font-semibold">
            Oh no! It looks like you’re lost in space...
          </h2>
          <p className="pt-4 pb-5 text-lg leading-6 text-dark-700">
            Just hit that button below and we’ll take you back down to Earth.
          </p>
          <div className="flex justify-center items-center">
            <LinkButton
              text="To the homepage & beyond"
              href="/"
              color="teal"
              className="w-fit !px-8"
            />
          </div>
          <div className="w-full md:w-61.9rem mx-auto mt-10">
            <GImage
              path="website/static/404/banner.png"
              alt="banner of 404 page"
            />
          </div>
        </div>
      </section>
    </main>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo();

  const meta = await getMeta(apolloClient, '/404');

  const navigationData = await navigationDataFn(apolloClient);
  const headerData = await getHeaderData(apolloClient);
  const offices = await getOfficeData(apolloClient);

  return {
    props: {
      meta,
      navigationData,
      headerData,
      offices,
    },
  };
};
